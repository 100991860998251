import * as React from "react"

function FaqArrowUpIcon(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.528 20.882a.75.75 0 01-1.06 0L7.82 13.235a.75.75 0 010-1.06l.354-.354a.75.75 0 011.06 0l6.763 6.763 6.763-6.763a.75.75 0 011.06 0l.354.354a.75.75 0 010 1.06l-7.647 7.647z"
        fill="#000"
      />
    </svg>
  )
}

export default FaqArrowUpIcon
