import * as React from "react"

function DeliveriesManagementIcon(props) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={50}
        height={50}
      >
        <path fill="#D9D9D9" d="M0 0H50V50H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M22.917 40.47V26.2l-12.5-7.24v14.27l12.5 7.24zm4.166 0l12.5-7.24V18.96l-12.5 7.24v14.27zm-4.166 4.792L8.333 36.876a4.14 4.14 0 01-1.536-1.51 4.059 4.059 0 01-.547-2.083V16.72c0-.764.182-1.458.547-2.083a4.14 4.14 0 011.536-1.51L22.917 4.74A4.096 4.096 0 0125 4.168c.73 0 1.424.19 2.083.573l14.584 8.385a4.14 4.14 0 011.536 1.51c.365.626.547 1.32.547 2.084v16.563c0 .763-.182 1.458-.547 2.083a4.14 4.14 0 01-1.536 1.51l-14.584 8.386a4.095 4.095 0 01-2.083.573c-.73 0-1.424-.191-2.083-.573zm10.416-27.5l4.01-2.292L25 8.335l-4.063 2.343 12.396 7.084zM25 22.605l4.063-2.343-12.344-7.136-4.063 2.344L25 22.605z"
          fill="#0540F2"
        />
      </g>
    </svg>
  )
}

export default DeliveriesManagementIcon
