import React from "react";

const UserFriendly = () => {
  return (
    <div>
      <h3 className="user-heading">
        A simple and user-friendly interface for
        <br /> receiving your guests.
      </h3>
      <p className="user-description">
        Welcome your guests with a hassle-free experience. Our intuitive
        interface ensures
        <br /> a smooth journey from check-in to departure, leaving a lasting
        impression of
        <br /> professionalism and convenience.
      </p>
    </div>
  );
};

export default UserFriendly;
