import React from "react";

const ContactUs = () => {
  return (
    <div>
      <h3 className="contact-heading">
       Still have questions?
      </h3>
      <button className="contact-button">
       Contact Us
      </button>
    </div>
  );
};

export default ContactUs;
