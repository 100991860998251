import React from "react";
import DownArrow from "../../../../public/assets/icons/DownArrow";
import { Link } from "gatsby";
import { SIGNUP_PAGE } from "../../../config/router";

const GetStarted = () => {
  return (
    <div className="get-container">
      <h3 className="get-title">Get started, its free!!</h3>
      <p className="started-now">
        <Link href="https://www.frontdesk.webbermill.com/account/signup" target="_blank" className="get-btn">
          Register now
        </Link>
      </p>
    </div>
  );
};

export default GetStarted;
