import * as React from "react"

function UserRegisterIcon(props) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={50}
        height={50}
      >
        <path fill="#D9D9D9" d="M0 0H50V50H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M37.499 29.17v-6.25h-6.25v-4.167h6.25v-6.25h4.166v6.25h6.25v4.166h-6.25v6.25H37.5zm-18.75-4.167c-2.292 0-4.254-.816-5.886-2.448-1.632-1.632-2.448-3.594-2.448-5.886 0-2.291.816-4.253 2.448-5.885 1.632-1.632 3.594-2.448 5.886-2.448 2.291 0 4.253.816 5.885 2.448 1.632 1.632 2.448 3.594 2.448 5.885 0 2.292-.816 4.254-2.448 5.886-1.632 1.632-3.594 2.448-5.885 2.448zM2.082 41.669v-5.833c0-1.18.304-2.266.911-3.255a6.075 6.075 0 012.422-2.266 30.93 30.93 0 016.563-2.422 28.663 28.663 0 016.77-.807c2.292 0 4.55.269 6.771.807a30.926 30.926 0 016.563 2.422 6.074 6.074 0 012.422 2.266c.608.99.911 2.074.911 3.255v5.833H2.082zm4.167-4.166h25v-1.667a2.027 2.027 0 00-1.042-1.77 27.159 27.159 0 00-5.677-2.11 24.17 24.17 0 00-5.781-.703c-1.945 0-3.872.234-5.782.703a27.16 27.16 0 00-5.677 2.11 2.027 2.027 0 00-.755.728c-.19.313-.286.66-.286 1.042v1.667zm12.5-16.667c1.146 0 2.126-.408 2.942-1.224.816-.816 1.224-1.797 1.224-2.943 0-1.146-.408-2.127-1.224-2.942-.816-.816-1.796-1.224-2.942-1.224-1.146 0-2.127.408-2.943 1.224-.816.816-1.224 1.796-1.224 2.942 0 1.146.408 2.127 1.224 2.943.816.816 1.797 1.224 2.943 1.224z"
          fill="#0540F2"
        />
      </g>
    </svg>
  )
}

export default UserRegisterIcon
