import React from "react";
import UserRegisterIcon from "../../../public/assets/icons/UserRegisterIcon";
import EasyCheckInIcon from "../../../public/assets/icons/EasyCheckInIcon";

const Visitor = () => {
  return (
    <div>
      <div>
        <span className="visitors-badge"><UserRegisterIcon /></span>
        <h4 className="visitor-register-heading">Visitor Registration</h4>
        <p className="visitor-register-description">
          Allow new visitors to check in easily by entering their<br />details,
          purpose of visit, and the person or<br /> department they are meeting.
        </p>
      </div>
      <div>
        <span className="visitors-badge"><EasyCheckInIcon /></span>
        <h4 className="easy-check-heading">Easy Check-In Check-Out</h4>
        <p className="easy-check-description">
          Effortlessly complete the check-in check-out process<br /> with just a few
          taps for a highly efficient and hassle-<br /> free experience.
        </p>
      </div>
    </div>
  );
};

export default Visitor;
