import React from "react";
import VisitorsManagementIcon from "../../../../public/assets/icons/VisitorsManagementIcon";

const VisitorManagement = () => {
  return (
    <div>
        <VisitorsManagementIcon />
      <h3 className="visitor-manage-heading">Visitor Management</h3>
      <p className="visitor-manage-description">
        Easily retrieve visitor information, including contact details, purpose
        of visit, check-in/check-out times, and host details, all at your
        fingertips.
      </p>
    </div>
  );
};

export default VisitorManagement;
