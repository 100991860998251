export let structuredData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Webbermill",
  legalName: "Webber Mill Limited",
  url: "https://webbermill.com/",
  logo: "https://res.cloudinary.com/webbermill/image/upload/v1627908224/webbermill-website/img/brand/logo_van6v7.svg",
  foundingDate: "2016",
  founders: [
    {
      "@type": "Person",
      name: "Charles K. Kotoko",
    },
    {
      "@type": "Person",
      name: "Saviour Yao Dorvlo",
    },
    {
      "@type": "Person",
      name: "Eric Elikplim Dorvlo",
    },
  ],
  address: {
    "@type": "PostalAddress",
    streetAddress: "GhanaPost GPS : GM-086-9119",
    addressLocality: "Alafia street, Pantang Rd. Adenta - Accra",
    addressRegion: "Accra",
    postalCode: "P.O Box TB248 Taifa Burkina",
    addressCountry: "Ghana",
  },
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "customer support",
    telephone: "[+233 303 968 409]",
    email: "enquiry@webbermill.com",
  },
  sameAs: [
    "https://www.facebook.com/WebberMillLtd/",
    "https://twitter.com/WebberMill",
    "https://www.linkedin.com/company/webbermill",
    "https://www.zoominfo.com/c/webber-mill-limited/481322709",
    "https://www.crunchbase.com/organization/webbermill",
    "https://g.page/r/CZEKANNc7CuHEBA",
  ],
};