import * as React from "react"

function EasyCheckInIcon(props) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={50}
        height={50}
      >
        <path fill="#D9D9D9" d="M0 0H50V50H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M4.168 33.333V27.5c0-1.18.304-2.266.911-3.255a6.075 6.075 0 012.422-2.266 30.93 30.93 0 016.563-2.422 28.663 28.663 0 016.77-.807c1.563 0 3.108.122 4.636.365s3.056.625 4.583 1.145a12.644 12.644 0 00-2.917 3.438 22.01 22.01 0 00-3.124-.599 27.38 27.38 0 00-3.177-.182c-1.945 0-3.872.234-5.782.703a27.155 27.155 0 00-5.677 2.11 2.027 2.027 0 00-.755.728c-.19.313-.286.66-.286 1.042v1.667h16.823a20.907 20.907 0 000 4.166H4.168zm16.667-16.666c-2.292 0-4.254-.816-5.886-2.448-1.632-1.632-2.448-3.594-2.448-5.886 0-2.291.816-4.253 2.448-5.885C16.581.816 18.543 0 20.835 0c2.291 0 4.253.816 5.885 2.448 1.632 1.632 2.448 3.594 2.448 5.885 0 2.292-.816 4.254-2.448 5.886-1.632 1.632-3.594 2.448-5.885 2.448zm0-4.167c1.145 0 2.126-.408 2.942-1.224.816-.816 1.224-1.797 1.224-2.943 0-1.146-.408-2.126-1.224-2.942-.816-.816-1.797-1.224-2.942-1.224-1.146 0-2.127.408-2.943 1.224-.816.816-1.224 1.796-1.224 2.942 0 1.146.408 2.127 1.224 2.943.816.816 1.797 1.224 2.943 1.224zm20.833-4.167c0 2.292-.816 4.254-2.448 5.886-1.632 1.632-3.594 2.448-5.885 2.448-.382 0-.868-.044-1.459-.13a12.739 12.739 0 01-1.458-.287 12.387 12.387 0 002.161-3.698c.504-1.354.756-2.76.756-4.219 0-1.458-.252-2.864-.756-4.218A12.387 12.387 0 0030.418.417a6.332 6.332 0 011.458-.339C32.362.026 32.85 0 33.335 0c2.291 0 4.253.816 5.885 2.448 1.632 1.632 2.448 3.594 2.448 5.885zm-2.083 33.334c-2.882 0-5.339-1.016-7.37-3.047-2.031-2.031-3.047-4.488-3.047-7.37 0-2.917 1.016-5.382 3.047-7.396 2.031-2.014 4.488-3.02 7.37-3.02 2.916 0 5.382 1.006 7.395 3.02 2.014 2.014 3.021 4.48 3.021 7.396 0 2.882-1.007 5.339-3.02 7.37-2.014 2.031-4.48 3.047-7.396 3.047zm-1.459-5.73l7.344-7.395-1.458-1.459-5.886 5.886L35.158 30l-1.459 1.51 4.427 4.427z"
          fill="#0540F2"
        />
      </g>
    </svg>
  )
}

export default EasyCheckInIcon
