// Laptop.js
import React, { useState, useEffect } from "react";
import LaptopImg from "../../../../public/assets/icons/LaptopImg";
import LaptopMob from "../../../../public/assets/icons/LaptopMob";
import LaptopTabImg from "../../../../public/assets/icons/LaptopTabImg";

const Laptop = () => {
  const [screenSize, setScreenSize] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setScreenSize("small");
      } else if (width >= 768 && width <= 1024) {
        setScreenSize("tablet");
      } else if (width === 1440 || width === 1600 || width === 1680) {
        setScreenSize("desk");
      } else if (width === 2560) {
        setScreenSize("extra");
      } else {
        setScreenSize("large");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="laptop-container">
      {screenSize === "small" && <div className="laptop-mobile"><LaptopMob /></div>}
      {screenSize === "tablet" && <div className="laptop-tablet"><LaptopTabImg /></div>}
      {["desk", "extra", "large"].includes(screenSize) && <div className="laptop-desktop"><LaptopImg /></div>}
    </div>
  );
};

export default Laptop;
