import React from "react";
import DeliveriesManagementIcon from "../../../../public/assets/icons/DeliveriesManagementIcon";

const DeliveriesManagement = () => {
  return (
    <div>
        <DeliveriesManagementIcon />
      <h3 className="deliveries-manage-heading">Delivery Management</h3>
      <p className="deliveries-manage-description">
        Easily log incoming deliveries, including details such as sender
        information, package contents, and expected delivery times.
      </p>
    </div>
  );
};

export default DeliveriesManagement;
