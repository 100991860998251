// Banner.js
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

const Banner = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="banner-container">
      <div className="custom-box">
        <span className="emoji">🔥</span>Maximize your productivity
      </div>
      <div className="content-container">
        <h1 className="title">
          A 100% free visitor <br /> management software
        </h1>
        <div className="cta-button">
          <Link
            href="https://www.frontdesk.webbermill.com/account/signup"
            target="_blank"
            className="cta-btn"
          >
            {isSmallScreen ? "Sign up for free" : "Get started"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
