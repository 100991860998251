import React, { Fragment, useState } from "react";
import FaqArrowUpIcon from "../../../../public/assets/icons/FaqArrowUpIcon";
import FaqArrowDownIcon from "../../../../public/assets/icons/FaqArrowDownIcon";

const data = [
  {
    question: "Is Webbermill FrontDesk completely free?",
    answer:
      "Yes, Webbermill FrontDesk is indeed provided to companies at no cost.We aim to offer a comprehensive visitor management solution without imposing any financial burden on our users. We host the software ourselves, regularly update it, and provide 24/7 customer support. <br /> <br /> However, please note that for hardware packages, we provide the kiosk for free, except for the tablet. We do offer Samsung tablet packages for your convenience. <br /> <br />  If you have any further questions about the hardware packages or any other aspect of Webbermill FrontDesk, please do not hesitate to reach out to our support team.",
  },
  {
    question: "Is Webbermill FrontDesk intended to replace your receptionist?",
    answer: "No, Webbermill FrontDesk is designed to complement and assist your receptionist in managing visitor check-ins and enhancing overall visitor management.<br /> <br /> It aims to streamline processes, allowing your receptionist to focus on providing a personalized and hospitable experience for your visitors. Your receptionist remains a crucial point of contact for visitors.",
  },
  {
    question: "What do I get if I sign up for Webbermill FrontDesk?",
    answer: "When you sign up for Webbermill FrontDesk, you gain access to a comprehensive visitor management solution that includes an efficient Android app for the visitor journey, a user-friendly dashboard for the receptionist, 24/7 customer support from Webbermill, and regular software updates. <br/> <br /> You also have the option for hardware packages, including a free kiosk with the choice to purchase Samsung tablets at a low cost for your setup.",
  },
];

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="faqs-container">
      <h3 className="faqs-heading">Frequently Asked Questions</h3>
      <p className="faqs-description">
        Explore our Frequently Asked Questions to find quick answers to common
        inquiries about <br />
        Webbermill and our solutions. If you can't find what you're looking for,
        feel free to contact us for <br /> personalized support.
      </p>
      <ul className="faqs-list">
        {data?.map((item, index) => (
          <li key={index} className="faqs-item">
            <button
              className={`faq-question-button ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
              <span className="arrow-icon">
                {activeIndex === index ? (
                  <FaqArrowDownIcon />
                ) : (
                  <FaqArrowUpIcon />
                )}
              </span>
            </button>
            {activeIndex === index && (
              <div dangerouslySetInnerHTML={{ __html: item.answer }} />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQs;
