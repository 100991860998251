import * as React from "react"
import HeaderComponent from "../../Components/HeaderComponent";
import Layout from '../../Components/Layout';
import Seo from "../../Components/Seo";
import FrontDesk from '../../modules/FrontDesk'
import { FooterComponent } from "../../Components/FooterComponent";

const index = () => {
    return (
        <>
            <Seo title="Front Desk | Webbermill"/>
            <Layout className="Legal">
                <title>Front Desk</title>
                <HeaderComponent hasProduct isfrontDesk/>
                <FrontDesk />
                <FooterComponent />
            </Layout>
        </>
    );
};

export default index;