import * as React from "react"

function VisitorsManagementIcon(props) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={50}
        height={50}
      >
        <path fill="#D9D9D9" d="M0 0H50V50H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M0 37.5v-3.281c0-1.493.764-2.709 2.292-3.646 1.527-.938 3.541-1.406 6.041-1.406.452 0 .886.008 1.302.026.417.017.816.06 1.198.13a8.67 8.67 0 00-1.093 2.292 8.539 8.539 0 00-.365 2.5V37.5H0zm12.5 0v-3.385c0-1.111.304-2.127.912-3.047.607-.92 1.466-1.728 2.578-2.422 1.11-.695 2.439-1.215 3.984-1.563 1.545-.347 3.22-.52 5.026-.52 1.84 0 3.533.173 5.078.52 1.545.348 2.873.868 3.985 1.563 1.11.694 1.961 1.502 2.552 2.422.59.92.885 1.936.885 3.047V37.5h-25zm28.125 0v-3.385c0-.903-.113-1.754-.339-2.553a8.157 8.157 0 00-1.015-2.24c.382-.069.772-.112 1.172-.13.399-.017.807-.025 1.224-.025 2.5 0 4.514.46 6.041 1.38 1.528.92 2.292 2.144 2.292 3.672V37.5h-9.375zm-23.698-4.167h16.198c-.347-.694-1.31-1.302-2.89-1.823-1.58-.52-3.325-.78-5.235-.78-1.91 0-3.654.26-5.234.78-1.58.521-2.526 1.129-2.839 1.823zm-8.594-6.25c-1.146 0-2.126-.408-2.942-1.224-.816-.816-1.224-1.797-1.224-2.942 0-1.18.408-2.17 1.224-2.97.816-.798 1.796-1.197 2.942-1.197 1.18 0 2.17.4 2.97 1.198.798.799 1.197 1.788 1.197 2.969 0 1.145-.4 2.126-1.198 2.942-.798.816-1.788 1.224-2.969 1.224zm33.334 0c-1.146 0-2.127-.408-2.943-1.224-.816-.816-1.224-1.797-1.224-2.942 0-1.18.408-2.17 1.224-2.97.816-.798 1.797-1.197 2.943-1.197 1.18 0 2.17.4 2.968 1.198.799.799 1.198 1.788 1.198 2.969 0 1.145-.399 2.126-1.198 2.942-.798.816-1.788 1.224-2.968 1.224zM25 25c-1.736 0-3.212-.608-4.427-1.823-1.215-1.215-1.823-2.69-1.823-4.427 0-1.77.608-3.255 1.823-4.453C21.788 13.099 23.263 12.5 25 12.5c1.77 0 3.255.599 4.453 1.797 1.198 1.198 1.797 2.682 1.797 4.453 0 1.736-.599 3.212-1.797 4.427C28.255 24.392 26.771 25 25 25zm0-4.167c.59 0 1.085-.2 1.484-.599.4-.399.6-.894.6-1.484s-.2-1.085-.6-1.484c-.399-.4-.894-.6-1.484-.6s-1.085.2-1.484.6c-.4.399-.6.894-.6 1.484s.2 1.085.6 1.484c.399.4.894.6 1.484.6z"
          fill="#0540F2"
        />
      </g>
    </svg>
  )
}

export default VisitorsManagementIcon
