// Index.js
import React, { useState, useEffect } from "react";
import BannerM from "../Home/components/BannerM";
import AboutM from "../Home/components/AboutM";
import FeaturesM from "../Home/components/FeaturesM";
import GetStartedM from "../Home/components/GetStartedM";
import "../../sass/pages/_frontDesk.scss";
import LaptopM from "../Home/components/LaptopM";
import "../../sass/responsive/_frontDesk.scss";
import UserFriendly from "../Home/components/UserFriendly";
import Visitor from "../Home/Visitor";
import TabletInsightImg from "../../../public/assets/icons/TabletInsightImg";
import TabletInsightImgTab from "../../../public/assets/icons/TabletInsightImgTab";
import DeliveriesImgTab from "../../../public/assets/icons/DeliveriesImgTab";
import DeliveriesImg from "../../../public/assets/icons/DeliveriesImg";
import Deliveries from "../Home/components/Deliveries";
import FAQs from "../Home/components/FAQs";
import ContactUs from "../Home/components/ContactUs";
import DeliveriesImgMob from "../../../public/assets/icons/DeliveriesImgMob";
import TabletInsightImgMob from "../../../public/assets/icons/TabletInsightImgMob";

const Index = () => {
  const [screenSize, setScreenSize] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 280 && width <= 768) {
        setScreenSize("small");
      } else if (
        width <= 1024 ||
        width === 1440 ||
        width === 1600 ||
        width === 1680
      ) {
        setScreenSize("tablet");
      } else if (width <= 2560) {
        setScreenSize("large");
      } else {
        setScreenSize("extra");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="front_desk">
      <div className="front_desk__content">
        <div className="main-container">
          <BannerM />
          <LaptopM />
        </div>
      </div>
      {screenSize !== "small" && (
        <div className="user-container">
          <UserFriendly />
        </div>
      )}
      {screenSize === "small" && (
        <div className="user-container-small">
          <h3 className="user-heading-small">
            A simple and user-friendly interface for
            <br /> receiving your guests.
          </h3>
          <p className="user-description-small">
            Welcome your guests with a hassle-free experience. Our intuitive
            interface ensures
            <br /> a smooth journey from check-in to departure, leaving a
            lasting impression of
            <br /> professionalism and convenience.
          </p>
        </div>
      )}
      <div className="component">
        {screenSize === "small" && <TabletInsightImgMob />}
      </div>
      <div className="visitor-container">
        <div className="visitor-component">
          <Visitor />
        </div>
        <div className="component">
          {screenSize === "tablet" && <TabletInsightImgTab />}
          {/* {screenSize === "small" && <TabletInsightImgMob />} */}
          {(screenSize === "large" || screenSize === "extra") && (
            <TabletInsightImgTab />
          )}
        </div>
      </div>
      <div className="deliveries-container">
        <div className="deliveries-component">
          {(screenSize === "large" || screenSize === "extra") && (
            <DeliveriesImgTab />
          )}
          {screenSize === "tablet" && <DeliveriesImgTab />}
          {screenSize === "small" && (
            <>
              <span className="deliveries-small">
                <h3 className="deliveries-heading">
                  A user-friendly yet powerful web <br />
                  app for managing your guests.
                </h3>
                <p className="deliveries-description">
                  Our web application simplifies guest management, enabling
                  efficient visitor data collection and ensuring a seamless,
                  secure experience for your organization and guests
                </p>
              </span>
              <span className="deliveries-small-img">
                <DeliveriesImgMob />
              </span>
            </>
          )}
        </div>
        {screenSize !== "small" && (
          <div className="component">
            <h3 className="deliveries-heading">
              A user-friendly yet powerful web <br />
              app for managing your guests.
            </h3>
            <p className="deliveries-description">
              Our web application simplifies guest management, enabling
              efficient visitor data collection and ensuring a seamless, secure
              experience for your organization and guests
            </p>
            <Deliveries />
          </div>
        )}
      </div>
      <GetStartedM />
      <div className="faqs-container">
        <FAQs />
      </div>
    </section>
  );
};

export default Index;
