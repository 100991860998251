import React from "react";
import VisitorManagement from "./VisitorManagement";
import DeliveriesManagement from "./DeliveriesManagement";

const Deliveries = () => {
  return (
    <div>
      <div className="manage">
        <div className="visitor-manage">
          <VisitorManagement />
        </div>
        <div className="deliveries-manage">
          <DeliveriesManagement />
        </div>
      </div>
    </div>
  );
};

export default Deliveries;
